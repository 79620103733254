import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="How Can I Achieve a Celebrity Smile? | Porcelain Veneers Toronto" 
    description="Are you confident in your smile? Do you find yourself wishing you had the pearly whites you keep seeing on screen? Find out how you can achieve your own celebrity smile." 
    keywords="Gummy Smile" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>How Can I Achieve a Celebrity Smile?</h1>
              <h2>Our Smile Stylist Considers These Three Things</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <h2>Are you 100% confident in your smile?</h2>
            <img alt="Blog Banner" src={ require("../../images/desktop/_blog/pics/may2017-sm.jpg" )} className="img-thumbnail img-news hidden-md hidden-lg" />
            <p className="lead">Do you ever wish you could bare your teeth with the confidence and poise of a famous celebrity?</p>
            <p>The truth is, anyone can light up a room and turn heads with an attractive smile. A perfect smile truly emanates confidence and success (it’s been proven time and time again)! But with that said, there are certain components that all beautiful smiles have in common.</p>

            <h3>Let’s talk about the three top components that determine how attractive a smile is:</h3>
            <ol>
              <li>
                <strong>Tooth Positioning</strong><br />
                Balance is a key component of a beautiful smile… that, and symmetry. The positioning of your teeth have a lot to do with how attractive your smile is to others. Are your teeth straight, or crooked? Is the midline (where your two front teeth meet) in alignment with the center of your face, or is it off-center? Are there any spaces or gaps between your teeth, or are any of your teeth crowded or overlapping one another? Our cosmetic dentist in Toronto stresses that, if the positioning of your teeth are anything less than ideal, this can be keeping you from achieving smile perfection.
              </li>
              <li>
                <strong>Gums</strong><br />
                When you smile, how much of your gums show? A person can be considered to have a “gummy smile” when there is a noticeable amount of gum tissue that shows above the top teeth when they smile (typically anything over 3mm of gum).<br /><br />
                <strong>Celebrities with gummy smiles include:</strong><br />
                Nicole Kidman<br />
                Gwen Stefani<br />
                Jon Heder<br /><br />
                <img alt="Allison Brie" src={ require("../../images/desktop/_blog/pics/may2017-lg.jpg" )} className="img-thumbnail" />
                <br />
                It’s also important to ensure that your gums are healthy, because this will show in your smile. Healthy gums appear pink in colour (not red) and are often lightly stippled in texture. If your gums are in an unhealthy state, your gums will show this. Gums that are receded or that have pulled away from the teeth also contribute to an unsightly appearance – so if you’ve experienced this oral issue, a gum grafting procedure will help to restore the appearance and health of your gums.
              </li>
              <li>
                <strong>Shade/Colour</strong><br />
                What is it that’s so attractive about a bright, white smile? Well, white teeth indicate great health and oral hygiene. A smile that is dull, yellow or non-uniform in colour can appear rather unpleasing to the eye. The teeth should appear consistent in shade, and should be white and vibrant to reveal optimal health and attractiveness.<br /><br />

                Well, there you have it! These are the most important aspects of a perfect smile. How does your smile measure up in all three categories?<br /><br />

                If you’re ready to dramatically transform your teeth into the perfect smile you’ve always dreamed of… Dr. Kaloti is your guy! Contact our Smile Stylist today to schedule a consultation.
              </li>
            </ol>
          </div>
          <div className="col-sm-4 blog-right">
            <img alt="Zac Efron" src={ require("../../images/desktop/_blog/pics/may2017-01.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.4s" /><br />
            <img alt="Celebrities With Gummy Smiles " src={ require("../../images/desktop/_blog/pics/may2017-02.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage